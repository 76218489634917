@tailwind base;
@tailwind components;

.tooltip {
  @apply invisible absolute scale-75 opacity-0 transition-all;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50 scale-100 opacity-100;
}

.has-tooltip-centered {
  @apply has-tooltip flex flex-col items-center justify-center;
}

.tooltip-white {
  @apply mt-14 rounded-lg border bg-white/80 p-1 px-2 text-sm shadow-md backdrop-blur-md;
}

.tooltip-black {
  @apply mt-14 rounded-lg border border-black bg-black/80 p-1 px-2 text-sm shadow-md backdrop-blur-md;
}

@tailwind utilities;

.marker-hidden::-webkit-details-marker {
  display: none;
}

.bg-white-blur {
  @apply bg-white;
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .bg-white-blur {
    @apply bg-white/70 backdrop-blur-md;
  }
}

.doc-text a {
  @apply text-blue-700 hover:text-blue-600;
}

@layer components {
  .button {
    @apply flex items-center justify-center
      rounded border border-gray-400/30 bg-white p-2 px-5 text-center text-black shadow-lg shadow-black/5
      ring-gray-500 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 active:bg-gray-200
      disabled:border-gray-100 disabled:bg-gray-200 disabled:text-gray-800 disabled:shadow-black/10;
  }

  .button-primary {
    @apply flex items-center justify-center
      rounded border border-blue-400 bg-blue-500 p-2 px-5 text-center text-white shadow-lg shadow-blue-300/30
      ring-blue-900 hover:bg-blue-600 focus:outline-none focus-visible:ring-2 active:bg-blue-400
      disabled:border-blue-100 disabled:bg-blue-200 disabled:text-blue-400 disabled:shadow-blue-300/10;
  }

  .button-danger {
    @apply rounded p-1 text-red-500 hover:bg-red-200 active:bg-red-500 active:text-white disabled:opacity-50;
  }
}

.default-text-styling h1 {
  @apply text-3xl font-bold mb-4;
}

.default-text-styling h2 {
  @apply text-2xl font-semibold mt-8 mb-4;
}

.default-text-styling h3 {
  @apply text-xl font-semibold mt-8 mb-4;
}

.default-text-styling h4 {
  @apply text-lg font-semibold mt-8 mb-4;
}

.default-text-styling p {
  @apply my-2;
}

.default-text-styling a {
  @apply text-blue-700 hover:text-blue-600;
}

.default-text-styling ul {
  @apply list-disc list-inside;
}

.default-text-styling ol {
  @apply list-decimal list-inside;
}

.default-text-styling li {
  @apply my-1 pl-2;
}

/* bold numeration */
.default-text-styling ol > li::marker {
  @apply font-semibold;
}

.default-text-styling blockquote {
  @apply border-l-4 border-gray-300 italic p-2;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes blur-in {
  from {
    opacity: 0.25;
    filter: blur(0.5rem);
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    filter: blur(0);
    transform: scale(1);
  }
}

@keyframes loading-bar {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
